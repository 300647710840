<template>
  <div v-on:click="toggleClass" :style="{ top: pTop, left: pLeft}" class="dot"></div>
</template>

<script>
export default {
  props: {
    pTop: String,
    pLeft: String,
    pImg: String,
    pHeight: String,
    pWidth: String,
  },
  methods: {
    toggleClass: function() {
      // console.log("activate dotContent" + this.pImg);
      // var els = document.getElementsByClassName("activated");
      // [].forEach.call(els, function (el) {
      //   el.classList.toggle("activated");
      // });

      // console.log(document.getElementById("dotContent"+this.pIg))
      
      // .classList.toggle("activated");
      //   // event.target.parentElement.classList.toggle("activated");
      this.$parent.ausgewaehlterTeppich = this.pImg;
    }
  },
}
</script>

<style>
  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    99% {
      opacity: 0;
    }
    100% {
      display: none;
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    99% {
      opacity: 1;
    }
    100% {
      display: block;
    }
  }
  @keyframes pulsing-dot {
    0% {
      /* opacity: 0.9; */
      transform: scale(0.9)
    }
    50% {
      /* opacity: 1; */
      transform: scale(1.0)
    }
    100% {
      /* opacity: 0.9; */
      transform: scale(0.9)
    }
  }

  .teppich-center { position: relative; }
  .teppich-overlay div svg { position: absolute; opacity: 0;  }
  .teppich-dot-content img { width: 100%; }
  .teppich-overlay .dot {
    z-index: 777;

    animation: 0.7s cubic-bezier(0.3, 1.19, 0.44, 0.44) infinite pulsing-dot;
    position: absolute;
    background-color: #1eaad2;
    /* text-align: center; */

    color: white;
    font-weight: 700;
    box-shadow: 0px 0px 0px 4px #1eaad2 inset;
  }
   /* .teppich-overlay .dot {
    border-radius: 7vw;
    width: 7vw;
    height: 7vw;
    line-height: 7vw;
  } */
  .teppich-overlay .dot {
    border-radius: 1.7em;
    width: 1.7em;
    height: 1.7em;
    line-height: 1.7em;
    box-shadow:#fff 0px 0px 0.07em 0.05em;
  }
  @media screen and (min-width: 430px) {
    .teppich-overlay .dot {
      width: 3.2em;
      height: 3.2em;
      line-height: 3.2em;
      border-radius: 3.2em;
    }
  }
  .absolute {
    position: absolute;
  }
  .teppich-overlay div .dot:hover {
    cursor: pointer;
    /* transform: scale(1.2); */
    border-color: black;
  }
  .teppich-overlay {
    /* top: 0; right: 0; bottom: 0; left: 0; */
    position: absolute;
  }

  .teppich-overlay>div {
    /* position: absolute; */
    /* width: 100%; */
  }

  /* .teppich-overlay div .teppich-dot-content.activated {
    /*animation: 1.5s linear 1 fade-in;*/
    /* opacity: 1;
  } */
  .teppich-overlay div .teppich-dot-content {
    box-shadow:rgb(255, 255, 255); box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    top: 4em;
    border: .2em solid #1eaad2;
    margin: 0px auto;
    border-radius: .3em;
    background-color: white;
    /*animation: 1.5s linear 1 fade-out;*/
    opacity: 0;
    /* width: 70%; */
   }
  .activated .teppich-dot-content, .activated svg {
    opacity: 1 !important;
  }
</style>