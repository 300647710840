<template>
  <div>
    <div class="content impressum">
      <div class="container clearfix"><div class="row"><div class="col-12 col-xl-8 offset-xl-2"><div id="c247" class="frame frame-default frame-type-text frame-layout-0 frame-space-after-cm-single"><p><strong>Carl Richard Montag Förderstiftung</strong><br> Gemeinnützige Stiftung<br> Raiffeisenstraße 2<br> 53113 Bonn</p><p>Telefon: +49 (0) 228 26716-226<br> Fax: +49 (0) 228 26716-266</p><p>E-Mail: <a href="javascript:linkTo_UnCryptMailto(%27ocknvq%2CkphqBoqpvci%5C%2Fuvkhvwpigp0fg%27);">info(at)montag-stiftungen.de</a></p><p>Vertretungsberechtigt und verantwortlich für den Inhalt:<br> Dr. Bernd Bach, Dr. Karl-Heinz Imhäuser</p><p>Aufsichtsbehörde: Bezirksregierung Köln</p><p>USt-IdNr. DE 196 050 299</p></div></div></div></div>
   </div>
  </div>
</template>

<script>
export default {
  data() {
  },
  components: {
  }
}

</script>

<style>
</style>