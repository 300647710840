<template>
  <div class="teppich-holder" v-on:click="hideAll">
 
    <div class="teppich-left">
      <div class="absolute">
        <div ref="sticky" class="teppich-side-sticky">
          <DotContent v-show="ausgewaehlterTeppich == 1" pImg="1" />
          <DotContent v-show="ausgewaehlterTeppich == 2"  pImg="2" />
          <DotContent v-show="ausgewaehlterTeppich == 3"  pImg="3" />
          <DotContent v-show="ausgewaehlterTeppich == 4"  pImg="4" />
          <DotContent v-show="ausgewaehlterTeppich == 5"  pImg="5" />
          <DotContent v-show="ausgewaehlterTeppich == 6"  pImg="6" />
          <DotContent v-show="ausgewaehlterTeppich == 7"  pImg="7" />
          <DotContent v-show="ausgewaehlterTeppich == 8"  pImg="8" />
          <DotContent v-show="ausgewaehlterTeppich == 9"  pImg="9" />
          <DotContent v-show="ausgewaehlterTeppich == 10"  pImg="10" />
          <DotContent v-show="ausgewaehlterTeppich == 11"  pImg="11" />
          <DotContent v-show="ausgewaehlterTeppich == 12"  pImg="12" />
          <DotContent v-show="ausgewaehlterTeppich == 13"  pImg="13" />
          <DotContent v-show="ausgewaehlterTeppich == 14"  pImg="14" />
          <DotContent v-show="ausgewaehlterTeppich == 15"  pImg="15" />
          <DotContent v-show="ausgewaehlterTeppich == 16"  pImg="16" />
          <DotContent v-show="ausgewaehlterTeppich == 17"  pImg="17" />
          <DotContent v-show="ausgewaehlterTeppich == 18"  pImg="18" />
          <DotContent v-show="ausgewaehlterTeppich == 19"  pImg="19" />
          <DotContent v-show="ausgewaehlterTeppich == 20"  pImg="20" />
          <DotContent v-show="ausgewaehlterTeppich == 21"  pImg="21" />
          <DotContent v-show="ausgewaehlterTeppich == 22"  pImg="22" />
          <DotContent v-show="ausgewaehlterTeppich == 23"  pImg="23" />
          <DotContent v-show="ausgewaehlterTeppich == 24"  pImg="24" />
          <DotContent v-show="ausgewaehlterTeppich == 25"  pImg="25" />
          <DotContent v-show="ausgewaehlterTeppich == 26"  pImg="26" />
          <DotContent v-show="ausgewaehlterTeppich == 27"  pImg="27" />
          <DotContent v-show="ausgewaehlterTeppich == 28"  pImg="28" />
          <DotContent v-show="ausgewaehlterTeppich == 29"  pImg="29" />
          <DotContent v-show="ausgewaehlterTeppich == 30"  pImg="30" />
          <DotContent v-show="ausgewaehlterTeppich == 31"  pImg="31" />
        </div>
      </div>
      

      <div class="targetImages img1"></div>
      <div class="targetImages img2"></div>
      <div class="targetImages img3"></div>
      <div class="targetImages img4"></div>
      <div class="targetImages img5"></div>
      <div class="targetImages img6"></div>
      <div class="targetImages img7"></div>
      <div class="targetImages img8"></div>
      <div class="targetImages img9"></div>
      <div class="targetImages img10"></div>
      <div class="targetImages img11"></div>
      <div class="targetImages img12"></div>
      <div class="targetImages img13"></div>
      <div class="targetImages img14"></div>
      <div class="targetImages img15"></div>
      <div class="targetImages img16"></div>
      <div class="targetImages img17"></div>
      <div class="targetImages img18"></div>
      <div class="targetImages img19"></div>
      <div class="targetImages img20"></div>
      <div class="targetImages img21"></div>
      <div class="targetImages img22"></div>
      <div class="targetImages img23"></div>




      <!-- <img class="karteikarte fade-in" src="tiles/webp/SK24082.webp" /> -->
      <div class="teppich-overlay">

        <!-- <div style="top: 22.2%;" class="">
          <svg width="100%" height="100%"><line x1="calc( 10% + 1.5em )" y1="1.5em" x2="50%" y2="1.5em" stroke-linecap="round" stroke-width="0.2em"  stroke="#1eaad2"/></svg>
          <svg width="100%" height="100%"><line x1="50%" y1="1.5em" x2="50%" y2="5em" stroke-width="0.2em" stroke-linecap="round" stroke="#1eaad2"/></svg>
          <div v-on:click="toggleClass" style="left: 14%;" class="dot">1</div>
          <div class="teppich-dot-content"><img :src="getImgUrl('1')"/></div>
        </div>

        <div style="top: 18%;" class="">
          <svg width="100%" height="100%"><line x1="calc( 40% + 1.5em )" y1="1.5em" x2="50%" y2="1.5em" stroke-linecap="round" stroke-width="0.2em"  stroke="#1eaad2"/></svg>
          <svg width="100%" height="100%"><line x1="50%" y1="1.5em" x2="50%" y2="5em" stroke-width="0.2em" stroke-linecap="round" stroke="#1eaad2"/></svg>
          <div v-on:click="toggleClass" style="left: 40%;" class="dot">2</div>
          <div class="teppich-dot-content"><img :src="getImgUrl('2')"/></div>
        </div>

        <div style="top: 30%;" class="">
          <svg width="100%" height="100%"><line x1="calc( 40% + 1.5em )" y1="1.5em" x2="50%" y2="1.5em" stroke-linecap="round" stroke-width="0.2em"  stroke="#1eaad2"/></svg>
          <svg width="100%" height="100%"><line x1="50%" y1="1.5em" x2="50%" y2="5em" stroke-width="0.2em" stroke-linecap="round" stroke="#1eaad2"/></svg>
          <div v-on:click="toggleClass" style="left: 40%;" class="dot">3</div>
          <div class="teppich-dot-content"><img :src="getImgUrl('3')"/></div>
        </div>

        <div style="top: 40%;" class="">
          <svg width="100%" height="100%"><line x1="calc( 40% + 1.5em )" y1="1.5em" x2="50%" y2="1.5em" stroke-linecap="round" stroke-width="0.2em"  stroke="#1eaad2"/></svg>
          <svg width="100%" height="100%"><line x1="50%" y1="1.5em" x2="50%" y2="5em" stroke-width="0.2em" stroke-linecap="round" stroke="#1eaad2"/></svg>
          <div v-on:click="toggleClass" style="left: 40%;" class="dot">3</div>
          <div class="teppich-dot-content"><img :src="getImgUrl('4')"/></div>
        </div>

      
        <div style="top: 40%;" class="">
          <svg width="100%" height="100%"><line x1="calc( 40% + 1.5em )" y1="1.5em" x2="50%" y2="1.5em" stroke-linecap="round" stroke-width="0.2em"  stroke="#1eaad2"/></svg>
          <svg width="100%" height="100%"><line x1="50%" y1="1.5em" x2="50%" y2="5em" stroke-width="0.2em" stroke-linecap="round" stroke="#1eaad2"/></svg>
          <div v-on:click="toggleClass" style="left: 40%;" class="dot">3</div>
          <div class="teppich-dot-content"><img :src="getImgUrl('4')"/></div>
        </div> -->


        <Dot pHeight="30" pWidth="30" pImg="1" pTop="23.2%" pLeft="24%" />
        <Dot pHeight="30" pWidth="30" pImg="2" pTop="17%" pLeft="32%" />
        <Dot pHeight="30" pWidth="30" pImg="3" pTop="30%" pLeft="60%" />
        <Dot pHeight="30" pWidth="30" pImg="4" pTop="60%" pLeft="20%" />
        <Dot pHeight="30" pWidth="30" pImg="5" pTop="70%" pLeft="29%" />
        <Dot pHeight="30" pWidth="30" pImg="6" pTop="8%" pLeft="30%" />
        <Dot pHeight="30" pWidth="30" pImg="7" pTop="62%" pLeft="68%" />
        <Dot pHeight="30" pWidth="30" pImg="8" pTop="52%" pLeft="49%" />
        <Dot pHeight="30" pWidth="30" pImg="9" pTop="56%" pLeft="60%" />
        <Dot pHeight="30" pWidth="30" pImg="10" pTop="87%" pLeft="45%" />
        <Dot pHeight="30" pWidth="30" pImg="11" pTop="34%" pLeft="68%" />
        <Dot pHeight="30" pWidth="30" pImg="12" pTop="68%" pLeft="65%" />
        <Dot pHeight="30" pWidth="30" pImg="13" pTop="22%" pLeft="55%" />
        <Dot pHeight="30" pWidth="30" pImg="14" pTop="5%" pLeft="65%"/>
        <Dot pHeight="30" pWidth="30" pImg="15" pTop="19%" pLeft="64%" />
        <Dot pHeight="30" pWidth="30" pImg="16" pTop="82%" pLeft="50%" />
        <Dot pHeight="30" pWidth="30" pImg="17" pTop="49%" pLeft="65%" />
        <Dot pHeight="30" pWidth="30" pImg="18" pTop="78%" pLeft="64%" />
        <Dot pHeight="30" pWidth="30" pImg="19" pTop="92%" pLeft="29%" />
        <Dot pHeight="30" pWidth="30" pImg="20" pTop="43%" pLeft="43%" />
        <Dot pHeight="30" pWidth="30" pImg="21" pTop="79%" pLeft="29%" />
        <Dot pHeight="30" pWidth="30" pImg="22" pTop="28%" pLeft="39%" />
        <Dot pHeight="30" pWidth="30" pImg="23" pTop="26%" pLeft="59%" />
        <Dot pHeight="30" pWidth="30" pImg="24" pTop="47%" pLeft="29%" />
        <Dot pHeight="30" pWidth="30" pImg="25" pTop="40%" pLeft="58%" />
        <Dot pHeight="30" pWidth="30" pImg="26" pTop="65%" pLeft="42%" />
        <Dot pHeight="30" pWidth="30" pImg="27" pTop="10%" pLeft="59%" />
        <Dot pHeight="30" pWidth="30" pImg="28"  pTop="36%" pLeft="29%" />
        <Dot pHeight="30" pWidth="30" pImg="29" pTop="74%" pLeft="59%" />
        <Dot pHeight="30" pWidth="30" pImg="30" pTop="32%" pLeft="19%" />
        <Dot pHeight="30" pWidth="30" pImg="31" pTop="13%" pLeft="40%" />



      </div>
    </div>
  </div>
</template>

<script>
import Dot from './Dot'
import DotContent from './DotContent'
export default {
  data() {
    return {
      ausgewaehlterTeppich: 0
    }
  },
  components: {
    Dot,
    DotContent
  },
  methods: {
    hideAll: function(event) {
      if(!event.target.classList.contains("dot")) {
        console.log("hide");
        // var els = document.getElementsByClassName("activated");
        // [].forEach.call(els, function (el) {
        //   el.classList.toggle("activated");
        // });
        this.ausgewaehlterTeppich = 0;
      }
      
    },
    toggleClass: function(event) {
    console.log("toggle class");
    var els = document.getElementsByClassName("activated");
    [].forEach.call(els, function (el) {
      el.classList.toggle("activated");
    });
      event.target.parentElement.classList.toggle("activated");
    },
    getImgUrl(pet) {
        var images = require.context('../assets/zitate', false, /\.webp$/)
        return images('./' + pet + ".webp")
      }
  },
}

</script>

<style>
  .teppich-holder {
    /* float: left; */
    max-width: 900px;
    margin: 0px auto;
    position: relative;
  }
  .teppich-left {
    /* position: relative; */
    /* margin: 0px auto; */
    /* left: -200; */
    width: 100%;
    /* float: left; MF ENABLEE?? */
    display: block;
  }
  .teppich-left img {
    width: 100%;
  }
  .teppich-side {
    margin: 0px auto;
    max-height: 70vh;
    max-width: 500px;
    top: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    margin-top: 0.2em;
    /* margin-bottom: -100em; */
    /* float: right; */
    display: block;
    max-width: 100%;
    width: 100%;
    position: absolute;
    min-height: calc( 22 * 100vw * 0.4724409448818898 );
  }

  .teppich-side-sticky {
    margin: auto;
    pointer-events: none;
    height: 90vh;
    z-index: 777;
    position: sticky;
    position: -webkit-sticky;
    top: .3em;
    /* margin: 0px auto; */
    margin: 1em;
    bottom: .3em;
  }
  .absolute { position: absolute; height: 100%; width: 100%; }

  .teppich-side-sticky div {

    max-width: 700px;
    text-align:center;
    margin: 1em auto;
    /* position: absolute; */
    z-index: 10;
    border: .1em solid #1eaad2;
    /* padding: 1em; */
    background-color: white;
    box-shadow:rgb(255, 255, 255); box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  /* .teppich-side-sticky { width: 100%; height: 100%; } */
  .teppich-side-sticky div { width: 100%; height: 100%; }

  .teppich-side-sticky img {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    /* top: 0; */
    /* right: 0; */
  }

  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    99% {
      opacity: 0;
    }
    100% {
      display: none;
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    99% {
      opacity: 1;
    }
    100% {
      display: block;
    }
  }
  @keyframes pulsing-dot {
    0% {
      /* opacity: 0.9; */
      transform: scale(0.9)
    }
    50% {
      /* opacity: 1; */
      transform: scale(1)
    }
    100% {
      /* opacity: 0.9; */
      transform: scale(0.9)
    }
  }

  .teppich-left { position: relative; }
  /* .teppich-overlay div { position: relative; } */
  .teppich-dot-content img { width: 20px; }
  .teppich-overlay div .dot {
    z-index: 777;
    border-radius: 1.5em;
    width: 2em;
    height: 2em;
    animation: 1.5s ease infinite pulsing-dot;
    position: absolute;
    background-color: #1eaad2;
    text-align: center;
    line-height: 2em;
    color: white;
    font-weight: 700;
    box-shadow: 0px 0px 0px 4px #1eaad2 inset;
  }
  .teppich-overlay div .dot:hover {
    cursor: pointer;
    transform: scale(1.2);
    border-color: black;
  }
  .teppich-overlay {
    z-index: 0;
    top: 0; right: 0; bottom: 0; left: 0;
    position: absolute;
  }

  .teppich-overlay>div {
    position: absolute;
    width: 100%;
  }

  /* .teppich-overlay div .teppich-dot-content.activated {
    /*animation: 1.5s linear 1 fade-in;*/
    /* opacity: 1;
  } */
  .teppich-overlay div .teppich-dot-content {
    box-shadow:rgb(255, 255, 255); box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    top: 4em;
    border: .2em solid #1eaad2;
    margin: 0px auto;
    border-radius: .3em;
    background-color: white;
    /*animation: 1.5s linear 1 fade-out;*/
    width: 70%;
   }

  .targetImages {
    position: relative;
    width: 100vw;
    height: calc( 100vw * 0.4724409448818898 );
    /* width: calc( 2286px / 10 );
    height: calc ( 1080px / 10 ); */
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;    
  }
  .img1 { background-image: url(~@/assets/carpet/1.webp); }
  .img2 { background-image: url(~@/assets/carpet/2.webp); }
  .img3 { background-image: url(~@/assets/carpet/3.webp); }
  .img4 { background-image: url(~@/assets/carpet/4.webp); }
  .img5 { background-image: url(~@/assets/carpet/5.webp); }
  .img6 { background-image: url(~@/assets/carpet/6.webp); }
  .img7 { background-image: url(~@/assets/carpet/7.webp); }
  .img8 { background-image: url(~@/assets/carpet/8.webp); }
  .img9 { background-image: url(~@/assets/carpet/9.webp); }
  .img10 { background-image: url(~@/assets/carpet/10.webp); }
  .img11 { background-image: url(~@/assets/carpet/11.webp); }
  .img12 { background-image: url(~@/assets/carpet/12.webp); }
  .img13 { background-image: url(~@/assets/carpet/13.webp); }
  .img14 { background-image: url(~@/assets/carpet/14.webp); }
  .img15 { background-image: url(~@/assets/carpet/15.webp); }
  .img16 { background-image: url(~@/assets/carpet/16.webp); }
  .img17 { background-image: url(~@/assets/carpet/17.webp); }
  .img18 { background-image: url(~@/assets/carpet/18.webp); }
  .img19 { background-image: url(~@/assets/carpet/19.webp); }
  .img20 { background-image: url(~@/assets/carpet/20.webp); }
  .img21 { background-image: url(~@/assets/carpet/21.webp); }
  .img22 { background-image: url(~@/assets/carpet/22.webp); }
  .img23 { background-image: url(~@/assets/carpet/23.webp); }

@media (min-width: 900px) {
/* @media screen and (min-device-width: 800px) and (max-aspect-ratio: 9 / 4) { */
/* @media screen and (min-device-width: 900px) and (max-aspect-ratio: 9 / 4) { */
  /* * { background-color: red; } */
  .teppich-left {
    width: 100%;
  }
  .teppich-side {
    margin: 0px auto;
    max-height: 70vh;
    max-width: 500px;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
  }
  .targetImages {
    width: calc( 450px * 2);
    height: calc( 200px * 2);
  }
  /*.teppich-side  {
    min-height: calc( 22 * 400px );
  } */
  .teppich-left  {
    min-height: calc( 22 * 400px );
  }

  .teppich-overlay>div {
    width: 450px;
  }

}

</style>