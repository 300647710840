<template>
  <div :style="{ backgroundImage: `url(${require('@/assets/zitate/'+pImg+'.webp')})`, 'background-size': 'contain', 'background-repeat': 'no-repeat', 'background-position': 'center' }">
    <span class="close"></span>
  </div>
</template>
<script>
export default {
  props: {
    pImg: String,
  }
}
</script>

<style>
.close {
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
}
.close:hover {
  opacity: 1;
}
.close:before, .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: #333;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

</style>